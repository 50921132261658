import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const updateCareProInfo = async (inputs) => {
	try {
		const data = await api.graph({
			query: `mutation {
					updateProviderInfo(
						profile_info: ${api.graphStringify(inputs)},
					) {
                        professional_statement
					}
				}`,
		});

		return data.updateProviderInfo;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateCareProInfo = () => {
	return useMutation({
		mutationKey: ['updateCareProInfo'],
		mutationFn: (values) => {
			return updateCareProInfo(values);
		},
	});
};
