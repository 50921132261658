import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledCarePlanManagementPage = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	max-width: 1050px;

	margin: auto;
`;

export const StyledHeadingContainer = styled.div`
	display: flex;
	text-align: left;

	width: fit-content;

	padding: 20px 0;
`;

export const StyledDescription = styled.p`
	font-size: 16px;
	line-height: 1.7;

	color: ${colors.primary.primary};

	margin-bottom: 20px;
`;

export const StyledCarePlanItemsContainer = styled.div`
	margin: 20px 0;
	flex-direction: row;
	display: grid;
	align-items: initial;
	grid-gap: 40px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	width: 100%;
`;
