import React from 'react';
import { useHistory } from 'react-router-dom';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import InformationTile from './InformationTile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faIdCardAlt, faListAlt } from '@fortawesome/free-solid-svg-icons';

import {
	StyledHomeContainer,
	StyledCopyContainer,
	StyledIntroContainer,
	StyledHeading,
	StyledDescription,
	StyledButtonsWrapper,
	StyledHeroImageContainer,
	StyledHeroImage,
	StyledInformationTilesContainer,
} from './styles';

const heroImImageSrc =
	'https://find-your-independents.s3.amazonaws.com/images/Home-Care-Jobs-in-Stone-Mountain-GA-1024x574.png';
const Home = () => {
	const history = useHistory();

	// handle get started button
	const handleGetStarted = () => {
		// push user to care-pro/auth/onboarding
		history.push('/care-pro/auth/onboarding');
	};
	return (
		<StyledHomeContainer>
			<StyledCopyContainer>
				<StyledIntroContainer>
					<StyledHeading>Take Control Of Your Business</StyledHeading>
					<StyledDescription>
						FYI was created with the goal of providing quality senior care services to
						help our clients remain at home.
					</StyledDescription>

					<StyledButtonsWrapper>
						<PrimaryButton onClick={handleGetStarted} size='small'>
							Get Started
						</PrimaryButton>
						<TransparentButton size='small'>Learn More</TransparentButton>
					</StyledButtonsWrapper>
				</StyledIntroContainer>
				<StyledHeroImageContainer>
					<StyledHeroImage src={heroImImageSrc}></StyledHeroImage>
				</StyledHeroImageContainer>
			</StyledCopyContainer>

			<StyledInformationTilesContainer>
				<InformationTile
					title='Manage Yourself'
					description='Manage your own schedule, clients, and services.'
					icon={faCopy}
				></InformationTile>
				<InformationTile
					title='Get paid'
					description='Get paid for the services you provide as quick as you perform them.'
					icon={faCopy}
				></InformationTile>
				<InformationTile
					title='Grow Your Business'
					description='Grow your business by providing quality services to your clients.'
					icon={faCopy}
				></InformationTile>
			</StyledInformationTilesContainer>
		</StyledHomeContainer>
	);
};

export default Home;
