import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobPostPage = styled.div`
	width: 100%;
	max-width: 1000px;

	display: flex;
	flex-direction: column;
	gap: 24px;

	margin: auto;
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledJobSection = styled.div`
	display: flex;
	gap: 24px;
`;

export const StyledActionsContainer = styled.div`
	width: 33%;

	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const StyledCareProsContainer = styled.div`
	width: 67%;

	background: ${colors.white};

	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	padding: 20px;
`;
