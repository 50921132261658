import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import NavBar from './components/NavBar';
import Wrapper from './components/Wrapper';

import AppRoutes from './routes';

import { coreRoutes } from './routes/constants';

import { AuthProvider } from './context/AuthContext';
import { useAuth } from './hooks/useAuth';

const CareProAppContent = () => {
	const { isAuthenticated, appIsLoading, hasCompletedPlatformOnboarding, statusUpdate } =
		useAuth();

	const history = useHistory();

	return (
		<>
			{appIsLoading && 'App is loading'}

			{!appIsLoading && (
				<>
					<NavBar statusUpdate={statusUpdate} />
					<Wrapper>
						<AppRoutes />
					</Wrapper>
				</>
			)}
		</>
	);
};

const CareProApp = () => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<CareProAppContent />
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default CareProApp;
