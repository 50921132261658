import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledHomeContainer = styled.div`
	width: 100%;

	@media (${mediaQueries.tablet}) {
		max-width: 1100px;
		margin: 0 auto;
	}
`;

export const StyledCopyContainer = styled.div`
	display: flex;
	flex-direction: column;

	padding: 25px 15px;

	@media (${mediaQueries.tablet}) {
		flex-direction: row;
		padding: 50px 25px;
	}
`;

export const StyledIntroContainer = styled.div`
	display: flex;
	flex-direction: column;

	margin-bottom: 1rem;

	width: 100%;

	@media (${mediaQueries.tablet}) {
		width: 50%;
	}
`;

export const StyledHeading = styled.h1`
	margin-bottom: 20px;
    color: ${colors.primary.primary};
    font-size: 60px;

	line-height: 1.4;
}
`;

export const StyledDescription = styled.p`
	font-size: 24px;
	color: ${colors.primary.primary};

	margin: 0 0 1.2rem;

	line-height: 1.7;
`;

export const StyledButtonsWrapper = styled.div``;

export const StyledHeroImageContainer = styled.div`
	width: 100%;
	display: flex;

	justify-content: center;

	@media (${mediaQueries.tablet}) {
		width: 50%;
	}
`;

export const StyledHeroImage = styled.img`
	width: 388px;
	height: 388px;
	border-radius: 50%;

	background-image-position: center;
	margin: auto;
`;

export const StyledInformationTilesContainer = styled.div`
	display: flex;
	flex-direction: column;

	padding: 25px 15px;

	justify-content: space-between;

	@media (${mediaQueries.tablet}) {
		padding: 50px 25px;
		flex-direction: row;
	}
`;
