import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import AuthCodeForm from '../../../components/AuthCodeForm';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../../../core/hooks/useAuth';
import { usePlatformOnboarding } from '../../../hooks/usePlatformOnboarding';
import { useRegisterCareProMutation } from '../../../api/mutations/useRegisterCareProMutation';

import { StyledAuthCodePage } from './styles';

import Text from 'AppComp/Text';

const AuthCodePage = () => {
	const history = useHistory();

	const [serverError, setServerError] = useState('');

	const { platformOnboardingState, mutatePlatformOnboardingState, resetPlatformOnboardingState } =
		usePlatformOnboarding();
	const { loginUser } = useAuth();
	const registercareProMutation = useRegisterCareProMutation();

	const authCodeHandler = async (values) => {
		try {
			const { token, user } = await registercareProMutation.mutateAsync({
				email: platformOnboardingState.accountInfo.data.email,
				password: platformOnboardingState.accountInfo.data.password,
				firstName: platformOnboardingState.personalInfo.data.firstName,
				lastName: platformOnboardingState.personalInfo.data.lastName,
				phone: platformOnboardingState.accountInfo.data.phone,
				displayName: platformOnboardingState.personalInfo.data.displayName,
				authCode: platformOnboardingState.authCode.data.authCode,
				gender: platformOnboardingState.personalInfo.data.gender,
			});

			await loginUser(token, user);

			mutatePlatformOnboardingState('authCode', {
				...platformOnboardingState.authCode,
				data: {
					code: values.code,
				},
				isCompleted: true,
			});

			resetPlatformOnboardingState();
		} catch (error) {
			console.error('Auth code verification / Registration failed', error);
			setServerError(error[0].message);
		}
	};

	const handleGoBack = () => {
		console.log('Go back');
		history.push('/care-pro/auth/onboarding/platform/account');
	};
	return (
		<StyledAuthCodePage>
			<PageHeading>Auth Code</PageHeading>
			<AuthCodeForm
				initialValues={{
					authCode: platformOnboardingState.authCode.data.authCode,
				}}
				authCodeHandler={authCodeHandler}
				serverError={serverError}
			/>
			<Text margin='20px 0 0 0' pointer onClick={handleGoBack}>
				Go Back
			</Text>
		</StyledAuthCodePage>
	);
};

export default AuthCodePage;
