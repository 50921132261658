import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import PageHeading from '../../../../core/components/PageHeading';
import LEGACY_Banking from '../../../components/LEGACY_Banking';

import { useStripeOnboarding } from '../../../hooks/useStripeOnboarding';
import { useUpdateCareProStripeInformationMutation } from '../../../api/mutations/useUpdateCareProStripeInformationMutation';

import { StyledPayoutsPage } from './styles';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const PayoutsPage = () => {
	const [serverError, setServerError] = useState('');

	const { stripeOnboardingState, mutateStripeOnboardingState } = useStripeOnboarding();
	const updateCareProStripeInformation = useUpdateCareProStripeInformationMutation();

	const payoutsHandler = async (values) => {
		try {
			await updateCareProStripeInformation.mutateAsync(values);

			mutateStripeOnboardingState('payouts', {
				...stripeOnboardingState.payouts,
				isCompleted: true,
			});
		} catch (error) {
			console.error('Phone / email verification failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledPayoutsPage>
			<PageHeading>Account Information</PageHeading>

			<Elements stripe={stripePromise}>
				<LEGACY_Banking updateProviderVerificationInfo={payoutsHandler} />
			</Elements>
		</StyledPayoutsPage>
	);
};

export default PayoutsPage;
