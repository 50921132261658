import { z } from 'zod';

const personalInfoFormValidationSchema = z.object({
	firstName: z.string().min(1, 'First name is required'),
	lastName: z.string().min(1, 'Last name is required'),
	gender: z.enum(['male', 'female'], 'Gender is required'),
	displayName: z.string().min(1, 'Display name is required'),
});

export default personalInfoFormValidationSchema;
