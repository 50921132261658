import styled from 'styled-components';

export const StyledJobPostsPage = styled.div`
	padding: 50px 25px;
	margin-bottom: 20px;

	width: 100%;
	max-width: 1100px;

	display: flex;
	gap: 40px;

	margin: auto;
`;

export const StyledHeadingSection = styled.div`
	width: 33%;
`;

export const StyledJobPostsContainer = styled.div`
	width: 67%;

	display: flex;
	flex-direction: column;
	gap: 20px;
`;
