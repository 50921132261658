import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils/';

export const StyledGuideContainer = styled.div`
	display: flex;
	align-items: center;

	border: 3px dashed ${colors.borderColor};
	border-radius: 8px;
	padding: 25px;
`;

export const StyledGuideWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1rem;
`;

export const StyledGuideHeading = styled.h2`
	font-size: 0.9rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: bold;

	color: ${colors.primary.primary};
`;

export const StyledGuideSteps = styled.ol`
	font-size: 0.75em;
	line-height: 1.4;

	display: flex;
	gap: 2rem;

	margin: 0;
	padding: 0 0.8rem;
	color: ${colors.primary.primary};
`;

export const StyledGuideStep = styled.li`
	font-size: 0.9rem;
	line-height: 1.4;

	gap: 1rem;
`;

export const StyledGuideStepWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const StyledGuideIconContainer = styled.div`
	position: relative;
	top: 2px;
`;
