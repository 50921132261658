import React from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import Text from '../../../../../corev2/Text';
import OnboardingButton from '../../components/OnboardingButton';

import { coreRoutes } from '../../../core/routes/constants';
import { authRoutes } from '../../routes/constants';

import { StripeOnboardingProvider } from '../../context/StripeOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useStripeOnboarding } from '../../hooks/useStripeOnboarding';

import {
	StyledOnboardingWelcomePage,
	StyledHeadingSection,
	StyledOnboardingButtonsContainer,
} from './styles';

const OnBoardingWelcomeContent = () => {
	const history = useHistory();

	const {
		isAuthenticated,
		hasSetProfessionalStatement,
		hasCompletedStripeOnboarding,
		hasCompletedPlatformOnboarding,
	} = useAuth();

	const { resetStripeOnboardingState } = useStripeOnboarding();

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.platform.home);
	};

	const goToProfessionalStatementPage = () => {
		history.push(authRoutes.onboarding.professionalStatement);
	};

	const goToStripeOnboardingPage = () => {
		history.push(authRoutes.onboarding.stripe.home);
	};

	const goToCareProAppPage = () => {
		resetStripeOnboardingState();
		history.push(coreRoutes.app);
	};

	return (
		<StyledOnboardingWelcomePage>
			<StyledHeadingSection>
				<PageHeading>Welcome To The FYI CarePro Community!</PageHeading>

				<Text size='extraLarge'>
					Tired of waiting for weekly or bi-weekly paychecks from payroll companies? Join
					the FYI marketplace and experience timely payments that put you in control of
					your earnings.
					<br />
					<br />
					FYI is a marketplace web service that puts you in control. At FYI, you have the
					power to set your own work schedule and determine your pay rates in
					collaboration with your clients, giving you the autonomy to manage your career
					on your terms.
				</Text>
			</StyledHeadingSection>

			<StyledOnboardingButtonsContainer>
				{!isAuthenticated && (
					<OnboardingButton text='Become a CarePro' onClickHandler={goToOnboardingPage} />
				)}

				{isAuthenticated && !hasSetProfessionalStatement && (
					<OnboardingButton
						text='Write a professional statement'
						onClickHandler={goToProfessionalStatementPage}
					/>
				)}

				{isAuthenticated &&
					hasSetProfessionalStatement &&
					!hasCompletedStripeOnboarding && (
						<OnboardingButton
							text='Verify your Stripe information'
							onClickHandler={goToStripeOnboardingPage}
						/>
					)}

				{isAuthenticated &&
					hasSetProfessionalStatement &&
					hasCompletedStripeOnboarding &&
					!hasCompletedPlatformOnboarding && (
						<OnboardingButton text='CarePro App' onClickHandler={goToCareProAppPage} />
					)}
			</StyledOnboardingButtonsContainer>
		</StyledOnboardingWelcomePage>
	);
};

const OnBoardingWelcomePage = () => {
	return (
		<StripeOnboardingProvider>
			<OnBoardingWelcomeContent />
		</StripeOnboardingProvider>
	);
};

export default OnBoardingWelcomePage;
