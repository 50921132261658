import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { CareProTile } from '../../../../../corev2/Tiles';

import PageHeading from '../../../core/components/PageHeading';

import JobStats from '../../components/JobStats';
import JobAction from '../../components/JobAction';

import { coreRoutes } from '../../../core/routes/constants';
import { jobPostsRoutes } from '../../routes/constants';
import { profileRoutes } from '../../../profiles/routes/constants';

import { JobPostProvider } from '../../context/JobPostContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';
import { useRenderCarePros } from '../../hooks/useRenderCarePros';

import { useSearchCareProsByCarePlanQuery } from '../../api/queries/useSearchCareProsByCarePlanQuery';
import { useInviteProviderToJobPost } from '../../api/mutations/useInviteProviderToJobPost';
import { useContactCareProMutation } from '../../api/mutations/useContactCareProMutation';

import {
	StyledJobPostPage,
	StyledPageHeadingContainer,
	StyledJobSection,
	StyledActionsContainer,
	StyledCareProsContainer,
} from './styles';

const JobPostContent = (props) => {
	const history = useHistory();
	const location = useLocation();
	const { jobPostId } = useParams();

	const { authUser } = useAuth();
	const { jobPost, isLoadingJobPost, updateJobPost, hasSetSchedule } = useJobPost();

	const { data: carePros, isLoading: isLoadingCarePros } = useSearchCareProsByCarePlanQuery(
		authUser.carePlan.id,
		1
	);

	const { stats, carePros: filteredCarePros } = useRenderCarePros(jobPost, carePros);

	const inviteCareProToJobPostMutation = useInviteProviderToJobPost();
	const contactCarePro = useContactCareProMutation();

	const goToCarePlanPage = () => {
		history.push(coreRoutes.carePlan);
	};

	const goToSchedulePage = () => {
		history.push(jobPostsRoutes.jobPostSchedule(jobPostId));
	};

	const changeCareProStatusParam = (status) => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set('status', status);
		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const inviteCareProToJobPostHandler = async (careProId) => {
		if (!hasSetSchedule) {
			alert('You need to set a schedule first.');
			return;
		}

		try {
			await inviteCareProToJobPostMutation.mutateAsync({ jobPostId, careProId });
			updateJobPost();
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const goToOfferCareProPage = async (careProId) => {
		if (!hasSetSchedule) {
			alert('You need to set a schedule first.');
			return;
		}

		history.push(jobPostsRoutes.jobPostOffer(jobPost.id, careProId));
	};

	const goToViewOfferPage = async (careProId) => {
		const job = jobPost.jobs.find((job) => job.provider.id === careProId);

		history.push(jobPostsRoutes.viewOfferPage(jobPost.id, job.id));
	};

	const chatWithCareProHandler = async (careProId) => {
		try {
			const data = await contactCarePro.mutateAsync({ careProId, userId: authUser.id });

			history.push(coreRoutes.chat);
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	const getHandler = (status) => {
		switch (status) {
			case 'Invite':
				return inviteCareProToJobPostHandler;
			case 'Offer':
				return goToOfferCareProPage;
			case 'View Offer':
				return goToViewOfferPage;
			case 'Chat':
				return chatWithCareProHandler;
			default:
				return null;
		}
	};

	if (isLoadingJobPost) {
		return <>Loading Job Post</>;
	}

	return (
		<>
			<StyledPageHeadingContainer>
				<PageHeading>{jobPost.title}</PageHeading>
			</StyledPageHeadingContainer>

			<JobStats stats={stats} changeCareProStatusParam={changeCareProStatusParam} />

			<StyledJobSection>
				<StyledActionsContainer>
					<JobAction
						title='Schedule'
						description='Manage your schedule to let CarePros know when you need care.'
						goToPage={goToSchedulePage}
					/>

					<JobAction
						title='Care Plan'
						description='Manage your Care Plan to let CarePros know what care you need.'
						goToPage={goToCarePlanPage}
					/>
				</StyledActionsContainer>

				<StyledCareProsContainer>
					{filteredCarePros &&
						filteredCarePros.map((carePro) => (
							<CareProTile
								key={carePro.id}
								id={carePro.id}
								displayName={carePro.displayName}
								rating={carePro.averageReview}
								hourlyRate={carePro.hourly_rate}
								professionalStatement={carePro.professional_statement}
								customButtonText={carePro.customText}
								onCustomButtonClick={getHandler(carePro.customText)}
								viewCareProHandler={viewCareProHandler}
							/>
						))}
				</StyledCareProsContainer>
			</StyledJobSection>
		</>
	);
};

const JobPostPage = () => {
	return (
		<JobPostProvider>
			<StyledJobPostPage>
				<JobPostContent />
			</StyledJobPostPage>
		</JobPostProvider>
	);
};

export default JobPostPage;
