import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import PageHeading from '../../../core/components/PageHeading';
import { CareProTile } from '../../../../../corev2/Tiles';
import JobOfferGuide from '../../components/JobOfferGuide';
import CareNeeds from '../../components/CareNeeds';

import { profileRoutes } from '../../../profiles/routes/constants';

import { JobPostProvider } from '../../context/JobPostContext';

import { useJobPost } from '../../hooks/useJobPost';

import { useFetchSelectedCareProQuery } from '../../api/queries/useFetchSelectedCareProQuery';

import {
	StyledJobPostOfferPage,
	StyledJobSection,
	StyledAction,
	StyledActionHeading,
	StyledStatusWrapper,
	StyledStatusIconContainer,
	StyledStatus,
	StyledActionsContainer,
	StyledMainContainer,
	StyledCareProsContainer,
} from './styles';

const ViewOfferPageContent = () => {
	const history = useHistory();
	const { jobOfferId } = useParams();

	const { jobPost, isLoadingJobPost } = useJobPost();

	if (isLoadingJobPost) {
		return <>Loading Job Post</>;
	}

	const job = jobPost.jobs.find((job) => job.id == jobOfferId);

	const { data: carePro, isLoading: isLoadingCarePro } = useFetchSelectedCareProQuery(
		job.provider.id
	);

	if (isLoadingCarePro) {
		return <>Loading Care Pro</>;
	}

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	return (
		<StyledJobPostOfferPage>
			<PageHeading>
				Offer made to: {carePro.displayName} for job "{jobPost.title}"
			</PageHeading>

			<JobOfferGuide />

			<StyledJobSection>
				<StyledActionsContainer>
					<StyledAction>
						<StyledActionHeading>Status</StyledActionHeading>

						<StyledStatusWrapper>
							<StyledStatusIconContainer>
								<FontAwesomeIcon icon={faExclamationCircle} size='lg' />
							</StyledStatusIconContainer>

							<StyledStatus>Offered</StyledStatus>
						</StyledStatusWrapper>
					</StyledAction>
				</StyledActionsContainer>

				<StyledMainContainer>
					<StyledCareProsContainer>
						<StyledActionHeading>Care Pro</StyledActionHeading>

						<CareProTile
							key={carePro.id}
							id={carePro.id}
							displayName={carePro.displayName}
							rating={carePro.averageReview}
							hourlyRate={carePro.hourly_rate}
							professionalStatement={carePro.professional_statement}
							viewCareProHandler={viewCareProHandler}
						/>
					</StyledCareProsContainer>

					<StyledCareProsContainer>
						<StyledActionHeading>Care Needs</StyledActionHeading>

						<CareNeeds />
					</StyledCareProsContainer>
				</StyledMainContainer>
			</StyledJobSection>
		</StyledJobPostOfferPage>
	);
};

const ViewOfferPage = () => {
	return (
		<JobPostProvider>
			<ViewOfferPageContent />
		</JobPostProvider>
	);
};

export default ViewOfferPage;
