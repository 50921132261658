import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import {
	StyledGuideContainer,
	StyledGuideWrapper,
	StyledGuideHeading,
	StyledGuideSteps,
	StyledGuideStep,
	StyledGuideStepWrapper,
	StyledGuideIconContainer,
} from './styles';

const JobOfferGuide = () => {
	return (
		<StyledGuideContainer>
			<StyledGuideWrapper>
				<StyledGuideHeading>JOB OFFER GUIDE</StyledGuideHeading>
				<StyledGuideSteps>
					<StyledGuideStep>
						<StyledGuideStepWrapper>
							Confirm a price
							<StyledGuideIconContainer>
								<FontAwesomeIcon icon={faChevronRight} size='md' />
							</StyledGuideIconContainer>
						</StyledGuideStepWrapper>
					</StyledGuideStep>
					<StyledGuideStep>
						<StyledGuideStepWrapper>
							Review Requests
							<StyledGuideIconContainer>
								<FontAwesomeIcon icon={faChevronRight} size='md' />
							</StyledGuideIconContainer>
						</StyledGuideStepWrapper>
					</StyledGuideStep>
					<StyledGuideStep>
						<StyledGuideStepWrapper>
							Review CarePro
							<StyledGuideIconContainer>
								<FontAwesomeIcon icon={faChevronRight} size='md' />
							</StyledGuideIconContainer>
						</StyledGuideStepWrapper>
					</StyledGuideStep>
					<StyledGuideStep>
						<StyledGuideStepWrapper>Send Offer</StyledGuideStepWrapper>
					</StyledGuideStep>
				</StyledGuideSteps>
			</StyledGuideWrapper>
		</StyledGuideContainer>
	);
};

export default JobOfferGuide;
