import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import AuthRoutes from '../../auth/routes/';

import { coreRoutes } from './constants';

const AppRoutes = () => {
	return (
		<Switch>
			<Route path={coreRoutes.auth}>
				<AuthRoutes />
			</Route>

			<Route path={coreRoutes.root} exact>
				<HomePage />
			</Route>
		</Switch>
	);
};

export default AppRoutes;
