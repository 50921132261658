import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, TextArea, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../../components/FormWrapper';
import InputContainer from '../../components/InputContainer';

import professionalStatementValidationSchema from './validation';

const ProfessionalStatementForm = (props) => {
	const { serverError, initialValues, professionalStatementHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => professionalStatementHandler(values),
		validationSchema: toFormikValidationSchema(professionalStatementValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<TextArea
						name='professionalStatement'
						onChange={formik.handleChange}
						placeholder='Professional Statement'
						value={formik.values.jobStatement}
					/>

					{formik.errors.jobStatement && (
						<ErrorMessage> {formik.errors.jobStatement} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Save
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default ProfessionalStatementForm;
