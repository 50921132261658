import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckUserSessionQuery } from '../../api/queries/useCheckUserSessionQuery';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isRegistryApproved, setIsRegistryApproved] = useState(false);
	const [authUser, setAuthUser] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState('Pending Registry Approval');
	const [hasCompletedPlatformOnboarding, setHasCompletedPlatformOnboarding] = useState(false);
	const [appIsLoading, setAppIsLoading] = useState(true);

	const { data, isLoading } = useCheckUserSessionQuery();

	useEffect(() => {
		if (!isLoading) {
			setAppIsLoading(false);
		}
	}, [isLoading]);

	useEffect(() => {
		if (authUser) {
			if (authUser.carePlan && authUser.carePlan.completed_platform_onboard) {
				setHasCompletedPlatformOnboarding(true);
			} else {
				setHasCompletedPlatformOnboarding(false);
			}
		}
	}, [authUser]);

	useEffect(() => {
		const token = getFromLocalStorage(localStorageKeys.FYI_AUTH_TOKEN);

		if (token && data) {
			if (data.carePlan && data.carePlan.completed_platform_onboard) {
				setHasCompletedPlatformOnboarding(true);
			} else {
				setHasCompletedPlatformOnboarding(false);
			}

			setAuthUser(data);
			setIsAuthenticated(true);
		} else {
			setAuthUser(null);
			setIsAuthenticated(false);
		}
	}, [data]);

	const loginUser = (token, user) => {
		setInLocalStorage(localStorageKeys.FYI_AUTH_TOKEN, token);
		setAuthUser(user);
		setIsAuthenticated(true);
	};

	const logoutUser = () => {
		setInLocalStorage(localStorageKeys.FYI_AUTH_TOKEN);
		setAuthUser(null);
		setIsAuthenticated(false);
	};

	const modifyAuthUser = (key, value) => {
		setAuthUser((prevAuthUser) => ({
			...prevAuthUser,
			[key]: value,
		}));
	};

	const changeRegistryApproval = (approval, status) => {
		setIsRegistryApproved(approval);
		if (status) setStatusUpdate(status);
	};

	const value = {
		isAuthenticated,
		isRegistryApproved,
		hasCompletedPlatformOnboarding,
		authUser,
		statusUpdate,
		appIsLoading,
		loginUser,
		logoutUser,
		changeRegistryApproval,
		modifyAuthUser,
	};

	console.log('auth context values', value);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
