import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import addressFormValidationSchema from './validation';

const AddressForm = (props) => {
	const { initialValues, addressFormHandler, serverError } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => addressFormHandler(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(addressFormValidationSchema),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Input
						name='addressLine1'
						type='text'
						onChange={formik.handleChange}
						placeholder='Address Line 1'
						value={formik.values.addressLine1}
					/>

					{formik.errors.addressLine1 && (
						<ErrorMessage> {formik.errors.addressLine1} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='addressLine2'
						type='text'
						onChange={formik.handleChange}
						placeholder='Address Line 2'
						value={formik.values.addressLine2}
					/>

					{formik.errors.addressLine2 && (
						<ErrorMessage> {formik.errors.addressLine2} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Input
						name='city'
						type='text'
						onChange={formik.handleChange}
						placeholder='City'
						value={formik.values.city}
					/>

					{formik.errors.city && <ErrorMessage> {formik.errors.city} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Input
						name='state'
						type='text'
						onChange={formik.handleChange}
						placeholder='State'
						value={formik.values.state}
					/>

					{formik.errors.state && <ErrorMessage> {formik.errors.state} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Input
						name='zip'
						type='text'
						onChange={formik.handleChange}
						placeholder='Zip'
						value={formik.values.zip}
					/>

					{formik.errors.zip && <ErrorMessage> {formik.errors.zip} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<PrimaryButton type='submit' size='small'>
						Next
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default AddressForm;
