import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import TOS from '../../../components/TOS';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useStripeOnboarding } from '../../../hooks/useStripeOnboarding';
import { useUpdateCareProStripeInformationMutation } from '../../../api/mutations/useUpdateCareProStripeInformationMutation';

import { StyledTOSPage } from './styles';

const TOSPage = () => {
	const [serverError, setServerError] = useState('');

	const { refreshAuthUser } = useAuth();
	const { stripeOnboardingState, mutateStripeOnboardingState } = useStripeOnboarding();
	const updateCareProStripeInformation = useUpdateCareProStripeInformationMutation();

	const tosHandler = async () => {
		try {
			await updateCareProStripeInformation.mutateAsync({
				tos_acceptance_ip_date: Math.floor(Date.now() / 1000).toString(),
			});

			mutateStripeOnboardingState('tos', {
				...stripeOnboardingState.tos,
				isCompleted: true,
			});

			refreshAuthUser();
		} catch (error) {
			console.error('Stripe TOS failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledTOSPage>
			<PageHeading> Terms of Service </PageHeading>

			<TOS tosHandler={tosHandler} />
		</StyledTOSPage>
	);
};

export default TOSPage;
