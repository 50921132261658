import { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const initialStats = {
	search: { title: 'Search', stat: 0, isActive: false, param: 'search' },
	invited: { title: 'Invited', stat: 0, isActive: false, param: 'invited' },
	applied: { title: 'Applied', stat: 0, isActive: false, param: 'applied' },
	offered: { title: 'Offered', stat: 0, isActive: false, param: 'offered' },
	active: { title: 'Active', stat: 0, isActive: false, param: 'active' },
	inactive: { title: 'Inactive', stat: 0, isActive: false, param: 'inactive' },
};

export const useRenderCarePros = (jobPost, carePros) => {
	const location = useLocation();
	const [stats, setStats] = useState(initialStats);
	const [filteredCarePros, setFilteredCarePros] = useState([]);

	const careProTypes = useMemo(() => {
		if (!jobPost || !carePros)
			return {
				searchCarePros: [],
				invitedCarePros: [],
				appliedCarePros: [],
				offeredCarePros: [],
				activeCarePros: [],
				declinedCarePros: [],
			};

		const invitedCareProIds =
			jobPost.applications
				.filter((application) => application.status === 'invited')
				.map((application) => application.provider_id) || [];

		const searchCarePros =
			carePros.filter((carePro) => !invitedCareProIds.includes(carePro.id)) || [];

		const invitedCarePros =
			jobPost.applications
				.filter((application) => application.status === 'invited')
				.map((application) => application.provider) || [];

		const appliedCarePros =
			jobPost.applications
				.filter((application) => application.status === 'applied')
				.filter(
					(application) =>
						!jobPost.jobs.find((job) => job.provider.id === application.provider_id)
				)
				.map((application) => application.provider) || [];

		const offeredCarePros =
			jobPost.jobs
				.filter((job) => job.status === 'offer' && job.offer_status === 'offered')
				.map((job) => job.provider) || [];

		const activeCarePros =
			jobPost.jobs
				.filter((job) => job.status === 'job' && job.job_status === 'active')
				.map((job) => job.provider) || [];

		const declinedCarePros =
			jobPost.jobs
				.filter((job) => job.status === 'offer' && job.offer_status === 'rejected')
				.map((job) => job.provider) || [];

		return {
			searchCarePros,
			invitedCarePros,
			appliedCarePros,
			offeredCarePros,
			activeCarePros,
			declinedCarePros,
		};
	}, [jobPost, carePros]);

	const getCustomText = (status) => {
		switch (status) {
			case 'search':
				return 'Invite';
			case 'invited':
				return 'Chat';
			case 'applied':
				return 'Offer';
			case 'offered':
				return 'View Offer';
			case 'active':
				return 'Chat';
			case 'inactive':
				return 'Chat';
			default:
				return 'Invite';
		}
	};

	useEffect(() => {
		if (!jobPost || !carePros) return;

		const params = new URLSearchParams(location.search);
		const status = params.get('status') || 'search';

		const updatedStats = {
			search: { ...stats.search, stat: careProTypes.searchCarePros.length },
			invited: { ...stats.invited, stat: careProTypes.invitedCarePros.length },
			applied: { ...stats.applied, stat: careProTypes.appliedCarePros.length },
			offered: { ...stats.offered, stat: careProTypes.offeredCarePros.length },
			active: { ...stats.active, stat: careProTypes.activeCarePros.length },
			inactive: { ...stats.inactive, stat: careProTypes.declinedCarePros.length },
		};

		Object.keys(updatedStats).forEach((key) => {
			updatedStats[key].isActive = key === status;
		});

		setStats(updatedStats);

		switch (status) {
			case 'search':
				setFilteredCarePros(
					careProTypes.searchCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('search'),
					}))
				);
				break;
			case 'invited':
				setFilteredCarePros(
					careProTypes.invitedCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('invited'),
					}))
				);
				break;
			case 'applied':
				setFilteredCarePros(
					careProTypes.appliedCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('applied'),
					}))
				);
				break;
			case 'offered':
				setFilteredCarePros(
					careProTypes.offeredCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('offered'),
					}))
				);
				break;
			case 'active':
				setFilteredCarePros(
					careProTypes.activeCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('active'),
					}))
				);
				break;
			case 'inactive':
				setFilteredCarePros(
					careProTypes.declinedCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('inactive'),
					}))
				);
				break;
			default:
				setFilteredCarePros(
					careProTypes.searchCarePros.map((carePro) => ({
						...carePro,
						customText: getCustomText('search'),
					}))
				);
				break;
		}
	}, [location.search, careProTypes]);

	return { carePros: filteredCarePros, stats };
};
