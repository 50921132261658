import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckCareProSessionQuery } from '../../api/queries/useCheckCareProSessionQuery';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isRegistryApproved, setIsRegistryApproved] = useState(false);
	const [authUser, setAuthUser] = useState(null);
	const [statusUpdate, setStatusUpdate] = useState('Pending Registry Approval');
	const [hasSetProfessionalStatement, setHasSetProfessionalStatement] = useState(false);
	const [hasCompletedStripeOnboarding, setHasCompletedStripeOnboarding] = useState(false);
	const [hasCompletedPlatformOnboarding, setHasCompletedPlatformOnboarding] = useState(false);

	const {
		data,
		isLoading: appIsLoading,
		refetch: refreshAuthUser,
	} = useCheckCareProSessionQuery();

	useEffect(() => {
		if (authUser) {
			if (authUser.professional_statement) {
				setHasSetProfessionalStatement(true);
			}

			if (authUser.stripeStatus.status !== 'unverified') {
				setHasCompletedStripeOnboarding(true);
			}

			if (authUser.careProRegistryStatus.platform_onboard) {
				setHasCompletedPlatformOnboarding(true);
			}
		}
	}, [authUser]);

	useEffect(() => {
		if (!appIsLoading) {
			const token = getFromLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);

			if (token && data) {
				setAuthUser(data);
				setIsAuthenticated(true);
			} else {
				setAuthUser(null);
				setIsAuthenticated(false);
				setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
			}
		}
	}, [data, appIsLoading]);

	const loginUser = (token, user) => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, token);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_LEGACY_AUTH_TOKEN, token);
		setAuthUser(user);
		setIsAuthenticated(true);
	};

	const logoutUser = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_LEGACY_AUTH_TOKEN);
		setAuthUser(null);
		setIsAuthenticated(false);
	};

	const modifyAuthUser = (key, value) => {
		setAuthUser((prevAuthUser) => ({
			...prevAuthUser,
			[key]: value,
		}));
	};

	const changeRegistryApproval = (approval, status) => {
		setIsRegistryApproved(approval);
		if (status) setStatusUpdate(status);
	};

	const value = {
		isAuthenticated,
		isRegistryApproved,
		hasSetProfessionalStatement,
		hasCompletedStripeOnboarding,
		hasCompletedPlatformOnboarding,
		authUser,
		statusUpdate,
		appIsLoading,
		loginUser,
		logoutUser,
		refreshAuthUser,
		changeRegistryApproval,
		modifyAuthUser,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
