import React from 'react';

import LEGACY_Profile from '../../components/LEGACY_Profile';

import { useFetchUserSavedPaymentMethodsQuery } from '../../api/queries/useFetchUserSavedPaymentMethodsQuery';
import { useUpdateUserMutation } from '../../api/mutations/useUpdateUserMutation';
import { useDeletePaymentMethodMutation } from '../../api/mutations/useDeletePaymentMethodMutation';
import { useUpdateDefaultPaymentMethodMutation } from '../../api/mutations/useUpdateDefaultPaymentMethodMutation';
import { useCreateUserStripeIntentMutation } from '../../api/mutations/useCreateUserStripeIntentMutation';

import { useAuth } from '../../../core/hooks/useAuth';

const DashboardPage = () => {
	const { authUser, logoutUser } = useAuth();

	const { data, isLoading, refetch: refreshUserData } = useFetchUserSavedPaymentMethodsQuery();

	const updateUser = useUpdateUserMutation();
	const deletePaymentMethod = useDeletePaymentMethodMutation();
	const updateDefaultPaymentMethod = useUpdateDefaultPaymentMethodMutation();
	const createUserStripeIntent = useCreateUserStripeIntentMutation();

	const updateUserhandler = async (userInputs) => {
		try {
			await updateUser.mutateAsync({
				userId: authUser.id,
				userInputs,
			});

			refreshUserData();
		} catch (error) {
			console.error('Updating the user failed', error);
		}
	};

	const updateDefaultPaymentMethodHandler = async (sourceId) => {
		console.log(sourceId);
		try {
			await updateDefaultPaymentMethod.mutateAsync({
				sourceId,
			});

			refreshUserData();
		} catch (error) {
			console.error('Updating default payment method failed', error);
		}
	};

	const deletePaymentMethodHandler = async (sourceId) => {
		try {
			await deletePaymentMethod.mutateAsync({
				sourceId,
				userId: authUser.id,
			});

			refreshUserData();
		} catch (error) {
			console.error('Deleting payment method failed', error);
		}
	};

	const createUserStripeIntentHandler = async () => {
		try {
			const stripeIntent = await createUserStripeIntent.mutateAsync();

			return stripeIntent;
		} catch (error) {
			console.error('Create user stripe intent failed', error);
		}
	};

	return (
		<LEGACY_Profile
			patient={!isLoading && authUser}
			savedPaymentMethods={!isLoading && data.payment_methods}
			carePlan={authUser.carePlan}
			logoutUser={logoutUser}
			updateUserHandler={updateUserhandler}
			deletePaymentMethodHandler={deletePaymentMethodHandler}
			updateDefaultPaymentMethodHandler={updateDefaultPaymentMethodHandler}
			createUserStripeIntentHandler={createUserStripeIntentHandler}
		/>
	);
};

export default DashboardPage;
