const colors = {
	primary: {
		primary: '#2b69a3',
		secondary: '#3882cc',
		accent: '#619cd6',
		background: '#89b5e1',
		text: '#b1cfec',
		border: '#dae8f6',
		highlight: '#84c4ff',
	},
	secondary: {
		primary: '#1e857b',
		secondary: '#25b1a7',
		accent: '#36d3c8',
		background: '#60dcd4',
		text: '#8ae5df',
		border: '#dae8f6',
		highlight: '#b7f0eb',
	},
	borderColor: '#2b69a64d',
	gray: '#969696',
	white: '#ffffff',
	pageBackground: '#edf6fa',
	warning: '#fa4362',
	forms: {
		fontColor: '#363636',
		formInputBorderColor: '#81b0df',
		placeholderColor: '#dddedf',
	},
	table: {
		header: '#f9fafb',
		border: '#e5e7eb',
		alternative: '#edf6fb',
		text: '#6b7280',
	},
};

export default colors;
