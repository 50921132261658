import React, { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccountInfoPage from './AccountInfoPage';
import PersonalInfoPage from './PersonalInfoPage';
import AuthCodePage from './AuthCodePage';

import Steps from '../../../../../corev2/Steps';

import { PlatformOnboardingProvider } from '../../context/PlatformOnboardingContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { usePlatformOnboarding } from '../../hooks/usePlatformOnboarding';

import { authRoutes } from '../../routes/constants';

import { StyledPlatformOnboardingPage, StyledStepsContainer } from './styles';

const PlatformOnboardingContent = ({ children }) => {
	const history = useHistory();
	const location = useLocation();

	const { isAuthenticated } = useAuth();
	const { platformOnboardingState } = usePlatformOnboarding();

	useLayoutEffect(() => {
		if (isAuthenticated) history.push(authRoutes.onboarding.home);

		const steps = Object.values(platformOnboardingState);
		const currentStep = steps.find((step) => !step.isCompleted);

		if (currentStep && location.pathname !== currentStep.route) {
			history.push(currentStep.route);
		} else if (!currentStep && location.pathname !== authRoutes.onboarding.home) {
			history.push(authRoutes.onboarding.home);
		}
	}, [isAuthenticated, platformOnboardingState, location, history]);

	console.log('platformOnboardingState', platformOnboardingState);

	// handles when a user clicks on a step or wants to go back to a previous step
	const handleClickToStep = (step) => {
		history.push(step.route);
	};
	return (
		<StyledPlatformOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={platformOnboardingState} />
			</StyledStepsContainer>
			{children}
		</StyledPlatformOnboardingPage>
	);
};

const PlatformOnboardingPage = (props) => {
	return (
		<PlatformOnboardingProvider>
			<PlatformOnboardingContent {...props} />
		</PlatformOnboardingProvider>
	);
};

export { AccountInfoPage, PersonalInfoPage, AuthCodePage };
export default PlatformOnboardingPage;
