import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';

import { useAuth } from '../../hooks/useAuth';

import { TransparentButton } from '../../../../../corev2/Buttons';

import {
	StyledBanner,
	StyledHeader,
	StyledWrapper,
	StyledLogo,
	StyledNavButtonsWrapper,
	StyledNavText,
} from './styles';

const NavBar = (props) => {
	const [logoURL, setLogoURL] = useState(
		'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png'
	);
	const history = useHistory();

	const { statusUpdate, isAuthenticated, hasCompletedPlatformOnboarding } = useAuth();

	const goToHomePage = () => {
		history.push(coreRoutes.root);
	};

	const goToCareProAppPage = () => {
		history.push(coreRoutes.app);
	};

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.home);
	};

	return (
		<>
			{statusUpdate && <StyledBanner>{statusUpdate}</StyledBanner>}

			<StyledHeader>
				<StyledWrapper>
					<StyledLogo onClick={goToHomePage} src={logoURL} />

					<StyledNavButtonsWrapper>
						{isAuthenticated && !hasCompletedPlatformOnboarding && (
							<TransparentButton onClick={goToOnboardingPage}>
								<StyledNavText>Onboard</StyledNavText>
							</TransparentButton>
						)}

						{isAuthenticated && hasCompletedPlatformOnboarding && (
							<TransparentButton onClick={goToCareProAppPage}>
								<StyledNavText>Platform</StyledNavText>
							</TransparentButton>
						)}

						{!isAuthenticated && (
							<TransparentButton
								styl={{
									cursor: 'pointer',
								}}
								size='small'
								onClick={goToOnboardingPage}
							>
								Signup
							</TransparentButton>
						)}
					</StyledNavButtonsWrapper>
				</StyledWrapper>
			</StyledHeader>
		</>
	);
};

export default NavBar;
