import React from 'react';
import { useParams } from 'react-router-dom';

import { CareProProfile } from '../../../../../corev2/Profiles';

import { useFetchCareProQuery } from '../../api/queries/useFetchCareProQuery';

const CareProProfilePage = () => {
	const { careProId } = useParams();

	const { data: carePro, isLoading: isLoadingCarePro } = useFetchCareProQuery(careProId);

	if (isLoadingCarePro) return <>Loading</>;
	return <CareProProfile carePro={carePro} traits={[]} />;
};

export default CareProProfilePage;
