export const coreRoutes = {
	root: '/client',
	auth: '/client/auth',
	carePlan: '/client/care-plan',
	jobPosts: '/client/job-posts',
	dashboard: '/client/dashboard',
	chat: '/client/chat',
	payments: '/client/payments',
	profiles: '/client/profiles',
};
